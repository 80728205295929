<template>
  <div>
    <!--begin::Dashboard-->
    <div class="row">
      <div class="col-xxl-4">
        <profile-widget :profile-status="profileStatus" />
      </div>

      <div class="col-xxl-4">
        <SubscriptionWidget
          :subscriptionOrder="lastSubscriptionOrder"
          :company="company"
        />
      </div>
      <!--      <div class="col-xxl-8">-->
      <!--        <OrdersWidget :subscriptionOrder="lastSubscriptionOrder" />-->
      <!--      </div>-->
      <div class="col-xxl-4" v-if="company.has_paid_subscription">
        <invitation-code-widget
          :user-profile="userProfile"
          :subscription-pricing="subscriptionPricing"
        />
      </div>
      <div class="col-xxl-4">
        <!--begin::Subscription Widget-->
        <div class="card card-custom gutter-b">
          <!--begin::Header-->
          <div class="card-header border-0 py-5">
            <h3 class="card-title align-items-start flex-column">
              <span class="card-label font-weight-bolder text-dark"
                >Portrait Download</span
              >
              <!--        <span class="text-muted mt-3 font-weight-bold font-size-sm"-->
              <!--          >More than 400+ new members</span-->
              <!--        >-->
            </h3>
          </div>
          <!--end::Header-->
          <!--begin::Body-->
          <div class="card-body pt-0 pb-3">
            <a
              v-bind:href="company.company_portrait_report"
              class="btn btn-primary font-weight-bold text-uppercase px-15 py-4 my-3 mr-3"
              data-wizard-type="action-submit"
              target="_blank"
            >
              Download
            </a>
          </div>
          <!--end::Body-->
        </div>
        <!--end::Subscription Widget-->
      </div>
    </div>
  </div>
</template>

<script>
// import UserService from "@/services/user.service";
// import { mapGetters } from "vuex";
import SubscriptionWidget from "@/view/content/widgets/SubscriptionWidget";
import ProfileWidget from "@/view/content/widgets/ProfileWidget";
import InvitationCodeWidget from "@/view/content/widgets/InvitationCodeWidget";
// import OrdersWidget from "@/view/content/widgets/OrdersWidget";
import {
  LOAD_LAST_DASHBOARD_SUBSCRIPTION_ORDER,
  LOAD_DASHBOARD_PROFILE_STATUS
} from "@/store/dashboard.module";
import { LOAD_USER_COMPANY } from "@/store/company.module";
import { LOAD_USER_PROFILE } from "@/store/profile.module";
import { LOAD_SUBSCRIPTION_PRICING } from "@/store/subscription.module";
import { mapGetters } from "vuex";

import ApiService from "@/services/api.service";

export default {
  components: { ProfileWidget, SubscriptionWidget, InvitationCodeWidget },
  mixins: [],
  name: "Dashboard",
  data() {
    return {
      lastSubscriptionOrder: {},
      profileStatus: {},
      company: {},
      userProfile: {},
      subscriptionPricing: {}
    };
  },
  mounted() {
    this.loadData();
    this.$recaptchaInstance.hideBadge();
  },
  methods: {
    loadData() {
      // Load first the pricing before the company is loaded
      Promise.all([
        this.$store.dispatch(LOAD_LAST_DASHBOARD_SUBSCRIPTION_ORDER),
        this.$store.dispatch(LOAD_DASHBOARD_PROFILE_STATUS),
        this.$store.dispatch(LOAD_USER_COMPANY),
        this.$store.dispatch(LOAD_USER_PROFILE),
        this.$store.dispatch(LOAD_SUBSCRIPTION_PRICING)
      ]).then(() => {
        this.lastSubscriptionOrder = this.getDashboardLastSubscriptionOrder;
        this.profileStatus = this.getDashboardProfileStatus;
        this.company = this.getUserCompany;
        this.userProfile = this.getUserProfile;
        this.subscriptionPricing = this.getSubscriptionPricing;
      });
    },
    downloadPortrait() {
      // set spinner to download button
      const downloadButton = this.$refs["kt_download_button"];
      downloadButton.classList.add("spinner", "spinner-light", "spinner-right");

      ApiService.setHeader();
      ApiService.getBlob("company/reports/portrait").then(response => {
        var fileURL = window.URL.createObjectURL(
          new Blob([response.data], { type: "application/pdf" })
        );
        var fileLink = document.createElement("a");

        fileLink.href = fileURL;
        fileLink.target = "_self";
        fileLink.download = this.company.company_name + ".pdf";
        // fileLink.setAttribute("download", "file.pdf");
        // document.body.appendChild(fileLink);

        fileLink.click();

        downloadButton.classList.remove(
          "spinner",
          "spinner-light",
          "spinner-right"
        );
      });
    }
  },
  computed: {
    ...mapGetters([
      "getDashboardLastSubscriptionOrder",
      "getUserCompany",
      "getUserProfile",
      "getDashboardProfileStatus",
      "getSubscriptionPricing"
    ])
  }
};
</script>
