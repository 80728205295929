var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex"},[_c('router-link',{attrs:{"to":_vm.routerUrl},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('b-link',{staticClass:"font-size-lg",on:{"click":navigate}},[_vm._v(_vm._s(_vm.label))])]}}])}),_c('span',{staticClass:"text-dark-75 d-block font-size-lg ml-auto"},[_vm._v(_vm._s(_vm.statusPrefix)+" "),_c('i',{class:[
        {
          'flaticon2-check-mark text-success': _vm.status
        },
        {
          'flaticon2-warning text-danger': !_vm.status
        }
      ]})])],1)}
var staticRenderFns = []

export { render, staticRenderFns }