var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card card-custom gutter-b"},[_c('div',{staticClass:"card-header border-0 py-5"},[_vm._m(0),_c('div',{staticClass:"card-toolbar"},[_c('b-dropdown',{attrs:{"size":"sm","variant":"link","toggle-class":"custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon","no-caret":"","right":"","no-flip":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('i',{staticClass:"ki ki-bold-more-hor"})]},proxy:true}])},[_c('div',{staticClass:"navi navi-hover min-w-md-250px"},[_c('b-dropdown-text',{staticClass:"navi-item",attrs:{"tag":"div"}},[_c('router-link',{attrs:{"to":"/adjust-subscription"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('a',{staticClass:"navi-link",on:{"click":navigate}},[_c('span',{staticClass:"navi-icon"},[_c('i',{staticClass:"flaticon2-setup"})]),_c('span',{staticClass:"navi-text"},[_vm._v("Abonement anpassen")])])]}}])})],1),_c('b-dropdown-text',{staticClass:"navi-item",attrs:{"tag":"div"}},[_c('router-link',{attrs:{"to":"/add-subscription-option"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('a',{staticClass:"navi-link",on:{"click":navigate}},[_c('span',{staticClass:"navi-icon"},[_c('i',{staticClass:"flaticon2-add"})]),_c('span',{staticClass:"navi-text"},[_vm._v("Optionen hinzufügen")])])]}}])})],1)],1)])],1)]),_c('div',{staticClass:"card-body pt-0 pb-3"},[(_vm.company.has_paid_subscription)?_c('div',{staticClass:"tab-content"},[_c('div',{staticClass:"table-responsive"},[_c('table',{staticClass:"table table-borderless table-vertical-center"},[_vm._m(1),_c('tbody',[[_c('tr',[_vm._m(2),_c('td',{staticClass:"py-5 text-right"},[_c('span',{staticClass:"text-dark d-block font-size-lg"},[_vm._v(" "+_vm._s(_vm.formatDateTime(_vm.subscriptionOrder.start_date))+" ")]),_c('span',{staticClass:"text-dark d-block font-size-lg"},[_vm._v(" "+_vm._s(_vm.formatDateTime(_vm.subscriptionOrder.end_date))+" ")]),_c('span',{staticClass:"text-dark d-block font-size-lg"},[_vm._v(" "+_vm._s(_vm.subscriptionOrder.nr_of_years)+" Jahre ")])])]),_c('tr',[_vm._m(3),_c('td',{staticClass:"py-5 text-right"},[_c('span',{staticClass:"text-dark d-block font-size-lg"},[_vm._v(" "+_vm._s(_vm.company.has_logo ? "Ja" : "Nein")+" ")]),_c('span',{staticClass:"text-dark d-block font-size-lg"},[_vm._v(" "+_vm._s(_vm.company.nr_of_branch_offices)+" ")]),_c('span',{staticClass:"text-dark d-block font-size-lg"},[_vm._v(" "+_vm._s(_vm.company.nr_of_bkp_numbers)+" ")]),_c('span',{staticClass:"text-dark d-block font-size-lg"},[_vm._v(" "+_vm._s(_vm.company.has_portrait ? "Ja" : "Nein")+" ")])])])]],2)])])]):_c('div',[_c('p',[_vm._v(" Sie haben noch kein kostenpflichtes Abonement abgeschlossen und können nur eingeschränkt Funktionen von Submit.ch nutzen. Schliessen Sie ein Abonement ab um den vollen Funktionsumfang von Submito.ch zu nutzen. ")]),_c('router-link',{attrs:{"to":"/adjust-subscription"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('b-button',{staticClass:"btn btn-primary font-weight-bold text-uppercase px-15 py-4 my-3 mr-3",attrs:{"variant":"primary"},on:{"click":navigate}},[_vm._v("Abonement abschliessen ")])]}}])})],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h3',{staticClass:"card-title align-items-start flex-column"},[_c('span',{staticClass:"card-label font-weight-bolder text-dark"},[_vm._v("Ihr Abonement")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',[_c('th',{staticClass:"p-0",staticStyle:{"min-width":"200px"}}),_c('th',{staticClass:"p-0",staticStyle:{"min-width":"50px"}})])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('td',{staticClass:"py-0"},[_c('span',{staticClass:"text-dark-75 d-block font-size-lg"},[_vm._v("Abgeschlossen am ")]),_c('span',{staticClass:"text-dark-75 d-block font-size-lg"},[_vm._v("Endet am ")]),_c('span',{staticClass:"text-dark-75 d-block font-size-lg"},[_vm._v("Vertragslaufzeit ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('td',{staticClass:"py-0"},[_c('span',{staticClass:"text-dark-75 d-block font-size-lg"},[_vm._v("Firmenlogo ")]),_c('span',{staticClass:"text-dark-75 d-block font-size-lg"},[_vm._v("Niederlassungen ")]),_c('span',{staticClass:"text-dark-75 d-block font-size-lg"},[_vm._v("BKP Nummern ")]),_c('span',{staticClass:"text-dark-75 d-block font-size-lg"},[_vm._v("Firmenportrait ")])])}]

export { render, staticRenderFns }