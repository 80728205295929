<template>
  <div class="d-flex">
    <router-link
      :to="routerUrl"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <b-link class="font-size-lg" @click="navigate">{{ label }}</b-link>
    </router-link>
    <span class="text-dark-75 d-block font-size-lg ml-auto"
      >{{ statusPrefix }}
      <i
        :class="[
          {
            'flaticon2-check-mark text-success': status
          },
          {
            'flaticon2-warning text-danger': !status
          }
        ]"
      ></i
    ></span>
  </div>
</template>

<script>
export default {
  name: "ProfileStatusIndication",
  props: {
    label: {
      required: true,
      Type: String
    },
    statusPrefix: {
      Type: String,
      default: ""
    },
    status: {
      required: true,
      Type: Boolean
    },
    routerUrl: {
      required: true,
      Type: String
    }
  }
};
</script>
