<template>
  <!--begin::Profile Widget-->
  <div class="card card-custom gutter-b">
    <!--begin::Header-->
    <div class="card-header border-0 pt-5">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label font-weight-bolder text-dark">Ihr Profil</span>
        <span class="text-muted mt-3 font-weight-bold font-size-sm"
          >Haben Sie Ihr Profile vollständig erfasst?</span
        >
      </h3>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body d-flex flex-column">
      <div class="flex-grow-1">
        <apexchart
          :options="chartOptions"
          :series="series"
          type="radialBar"
        ></apexchart>
      </div>

      <div class="pt-5">
        <profile-status-indication
          :status="profileStatus.is_company_setup"
          label="Firma erfasst"
          router-url="/company"
        />
        <profile-status-indication
          v-if="profileStatus.has_logo"
          :status="profileStatus.is_logo_uploaded"
          label="Firmenlogo hochgeladen"
          router-url="/company"
        />
        <profile-status-indication
          v-if="profileStatus.has_company_details"
          :status="profileStatus.is_company_details_setup"
          label="Firmendetails erfasst"
          router-url="/company-details"
        />
        <profile-status-indication
          v-if="profileStatus.has_portrait"
          :status="profileStatus.is_portrait_setup"
          label="Firmenportrait erfasst"
          router-url="/company-portrait"
        />
        <profile-status-indication
          v-if="profileStatus.has_bkp_numbers"
          :status="
            profileStatus.nr_of_bkp_numbers ==
              profileStatus.nr_of_bkp_numbers_setup
          "
          label="BKP Nummern erfasst"
          :status-prefix="
            profileStatus.nr_of_bkp_numbers_setup +
              '/' +
              profileStatus.nr_of_bkp_numbers
          "
          router-url="/company-services"
        />
        <profile-status-indication
          v-if="profileStatus.has_branch_offices"
          :status="
            profileStatus.nr_of_branch_offices ==
              profileStatus.nr_of_branch_offices_setup
          "
          label="Niderlassungen erfasst"
          :status-prefix="
            profileStatus.nr_of_branch_offices_setup +
              '/' +
              profileStatus.nr_of_branch_offices
          "
          router-url="/branch-offices"
        />
      </div>

      <!--      <div class="pt-5">-->
      <!--        <a-->
      <!--          href="#"-->
      <!--          class="btn btn-success btn-shadow-hover font-weight-bolder w-100 py-3"-->
      <!--          >Generate Report</a-->
      <!--        >-->
      <!--      </div>-->
    </div>
    <!--end::Body-->
  </div>
  <!--end::Profile Widget-->
</template>

<script>
import { mapGetters } from "vuex";
import ProfileStatusIndication from "@/view/content/widgets/components/ProfileStatusIndication";

export default {
  name: "ProfileWidget",
  components: { ProfileStatusIndication },
  props: {
    profileStatus: {
      required: true,
      Type: Object
    }
  },
  data() {
    return {
      chartOptions: {}
    };
  },
  computed: {
    ...mapGetters(["layoutConfig"]),
    series: {
      get() {
        if (this.profileStatus.procentage_of_setup != undefined) {
          return [this.profileStatus.procentage_of_setup];
        }
        return [0];
      }
    }
  },
  mounted() {
    // reference; kt_stats_widget_7_chart
    this.chartOptions = {
      chart: {
        height: 200,
        type: "radialBar"
      },
      plotOptions: {
        radialBar: {
          hollow: {
            margin: 0,
            size: "65%"
          },
          dataLabels: {
            showOn: "always",
            name: {
              show: false,
              fontWeight: "700"
            },
            value: {
              color: this.layoutConfig("colors.gray.gray-700"),
              fontSize: "30px",
              fontWeight: "700",
              offsetY: 12,
              show: true
            }
          },
          track: {
            background: this.layoutConfig("colors.theme.light.success"),
            strokeWidth: "100%"
          }
        }
      },
      colors: [this.layoutConfig("colors.theme.base.success")],
      stroke: {
        lineCap: "round"
      },
      labels: ["Progress"]
    };
  }
};
</script>
