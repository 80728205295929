<template>
  <!--begin::Subscription Widget-->
  <div class="card card-custom gutter-b">
    <!--begin::Header-->
    <div class="card-header border-0 py-5">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label font-weight-bolder text-dark"
          >Ihr Abonement</span
        >
        <!--        <span class="text-muted mt-3 font-weight-bold font-size-sm"-->
        <!--          >More than 400+ new members</span-->
        <!--        >-->
      </h3>
      <div class="card-toolbar">
        <b-dropdown
          size="sm"
          variant="link"
          toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
          no-caret
          right
          no-flip
        >
          <template v-slot:button-content>
            <i class="ki ki-bold-more-hor"></i>
          </template>
          <!--begin::Navigation-->
          <div class="navi navi-hover min-w-md-250px">
            <!--            <b-dropdown-text tag="div" class="navi-item">-->
            <!--              <router-link-->
            <!--                to="/adjust-subscription"-->
            <!--                v-slot="{ href, navigate, isActive, isExactActive }"-->
            <!--              >-->
            <!--                <a-->
            <!--                  class="btn btn-info font-weight-bolder font-size-sm mr-3"-->
            <!--                  style="width: 100%;"-->
            <!--                  @click="navigate"-->
            <!--                  >Abonmenet anpassen</a-->
            <!--                >-->
            <!--              </router-link>-->
            <!--            </b-dropdown-text>-->
            <!--            <b-dropdown-text tag="div" class="navi-item">-->
            <!--              <router-link-->
            <!--                to="/add-subscription-option"-->
            <!--                v-slot="{ href, navigate, isActive, isExactActive }"-->
            <!--              >-->
            <!--                <a-->
            <!--                  class="btn btn-success font-weight-bolder font-size-sm"-->
            <!--                  style="width: 100%;"-->
            <!--                  @click="navigate"-->
            <!--                  >Optionen hinzufügen</a-->
            <!--                >-->
            <!--              </router-link>-->
            <!--            </b-dropdown-text>-->
            <b-dropdown-text tag="div" class="navi-item">
              <router-link
                to="/adjust-subscription"
                v-slot="{ href, navigate, isActive, isExactActive }"
              >
                <a class="navi-link" @click="navigate">
                  <span class="navi-icon">
                    <i class="flaticon2-setup"></i>
                  </span>
                  <span class="navi-text">Abonement anpassen</span>
                </a>
              </router-link>
            </b-dropdown-text>
            <b-dropdown-text tag="div" class="navi-item">
              <router-link
                to="/add-subscription-option"
                v-slot="{ href, navigate, isActive, isExactActive }"
              >
                <a class="navi-link" @click="navigate">
                  <span class="navi-icon">
                    <i class="flaticon2-add"></i>
                  </span>
                  <span class="navi-text">Optionen hinzufügen</span>
                </a>
              </router-link>
            </b-dropdown-text>
          </div>
          <!--end::Navigation-->
        </b-dropdown>
        <!--        <router-link-->
        <!--          to="/adjust-subscription"-->
        <!--          v-slot="{ href, navigate, isActive, isExactActive }"-->
        <!--        >-->
        <!--          <a-->
        <!--            class="btn btn-info font-weight-bolder font-size-sm mr-3"-->
        <!--            @click="navigate"-->
        <!--            >Abonmenet anpassen</a-->
        <!--          >-->
        <!--        </router-link>-->
        <!--        <router-link-->
        <!--          to="/add-subscription-option"-->
        <!--          v-slot="{ href, navigate, isActive, isExactActive }"-->
        <!--        >-->
        <!--          <a-->
        <!--            class="btn btn-success font-weight-bolder font-size-sm"-->
        <!--            @click="navigate"-->
        <!--            >Optionen hinzufügen</a-->
        <!--          >-->
        <!--        </router-link>-->
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body pt-0 pb-3">
      <div v-if="company.has_paid_subscription" class="tab-content">
        <!--begin::Table-->
        <div class="table-responsive">
          <table class="table table-borderless table-vertical-center">
            <thead>
              <tr>
                <th class="p-0" style="min-width: 200px"></th>
                <th class="p-0" style="min-width: 50px"></th>
              </tr>
            </thead>
            <tbody>
              <template>
                <tr>
                  <td class="py-0">
                    <span class="text-dark-75 d-block font-size-lg"
                      >Abgeschlossen am
                    </span>
                    <span class="text-dark-75 d-block font-size-lg"
                      >Endet am
                    </span>
                    <span class="text-dark-75 d-block font-size-lg"
                      >Vertragslaufzeit
                    </span>
                  </td>
                  <td class="py-5 text-right">
                    <span class="text-dark d-block font-size-lg">
                      {{ formatDateTime(subscriptionOrder.start_date) }}
                    </span>
                    <span class="text-dark d-block font-size-lg">
                      {{ formatDateTime(subscriptionOrder.end_date) }}
                    </span>
                    <span class="text-dark d-block font-size-lg">
                      {{ subscriptionOrder.nr_of_years }} Jahre
                    </span>
                  </td>
                </tr>
                <tr>
                  <td class="py-0">
                    <span class="text-dark-75 d-block font-size-lg"
                      >Firmenlogo
                    </span>
                    <span class="text-dark-75 d-block font-size-lg"
                      >Niederlassungen
                    </span>
                    <span class="text-dark-75 d-block font-size-lg"
                      >BKP Nummern
                    </span>
                    <span class="text-dark-75 d-block font-size-lg"
                      >Firmenportrait
                    </span>
                  </td>
                  <td class="py-5 text-right">
                    <span class="text-dark d-block font-size-lg">
                      {{ company.has_logo ? "Ja" : "Nein" }}
                    </span>
                    <span class="text-dark d-block font-size-lg">
                      {{ company.nr_of_branch_offices }}
                    </span>
                    <span class="text-dark d-block font-size-lg">
                      {{ company.nr_of_bkp_numbers }}
                    </span>
                    <span class="text-dark d-block font-size-lg">
                      {{ company.has_portrait ? "Ja" : "Nein" }}
                    </span>
                  </td>
                </tr>
                <!--                <OrdersPropertyTableRow-->
                <!--                  name="Abgeschlossen am"-->
                <!--                  :property="formatDateTime(subscriptionOrder.start_date)"-->
                <!--                  sub-name="Endet am"-->
                <!--                  :subProperty="formatDateTime(subscriptionOrder.end_date)"-->
                <!--                />-->
                <!--                <OrdersPropertyTableRow-->
                <!--                  name="Endet am"-->
                <!--                  :property="formatDateTime(subscriptionOrder.end_date)"-->
                <!--                />-->
                <!--                <OrdersPropertyTableRow-->
                <!--                  name="Laufzeit"-->
                <!--                  :property="subscriptionOrder.nr_of_years + ' Jahre'"-->
                <!--                />-->
                <!--                <OrdersPropertyTableRow-->
                <!--                  name="Firmenlogo"-->
                <!--                  :property="company.has_logo ? 'Ja' : 'Nein'"-->
                <!--                />-->
                <!--                <OrdersPropertyTableRow-->
                <!--                  name="# Niederlassungen"-->
                <!--                  :property="company.nr_of_branch_offices"-->
                <!--                />-->
                <!--                <OrdersPropertyTableRow-->
                <!--                  name="# BKPs"-->
                <!--                  :property="company.nr_of_bkp_numbers"-->
                <!--                />-->
                <!--                <OrdersPropertyTableRow-->
                <!--                  name="Firmenportrait"-->
                <!--                  :property="company.has_portrait ? 'Ja' : 'Nein'"-->
                <!--                />-->

                <!--                  <td class="text-center">-->
                <!--                    <span-->
                <!--                      class="text-dark-75 font-weight-bolder d-block font-size-lg"-->
                <!--                      ># Niederlassungen</span-->
                <!--                    >-->
                <!--                    <span class="text-muted font-weight-bold">1</span>-->
                <!--                  </td>-->
                <!--                  <td class="text-center">-->
                <!--                    <span-->
                <!--                      class="text-dark-75 font-weight-bolder d-block font-size-lg"-->
                <!--                      ># BKP</span-->
                <!--                    >-->
                <!--                    <span class="text-muted font-weight-bold">1</span>-->
                <!--                  </td>-->
                <!--                  <td class="text-center">-->
                <!--                    <span-->
                <!--                      class="text-dark-75 font-weight-bolder d-block font-size-lg"-->
                <!--                      >Firmenprofil</span-->
                <!--                    >-->
                <!--                    <span class="text-muted font-weight-bold">-->
                <!--                      {{ subscriptionOrder.has_portrait ? "Ja" : "Nein" }}-->
                <!--                    </span>-->
                <!--                  </td>-->
              </template>
            </tbody>
          </table>
        </div>
        <!--end::Table-->
      </div>
      <div v-else>
        <p>
          Sie haben noch kein kostenpflichtes Abonement abgeschlossen und können
          nur eingeschränkt Funktionen von Submit.ch nutzen. Schliessen Sie ein
          Abonement ab um den vollen Funktionsumfang von Submito.ch zu nutzen.
        </p>
        <router-link
          to="/adjust-subscription"
          v-slot="{ href, navigate, isActive, isExactActive }"
        >
          <b-button
            variant="primary"
            @click="navigate"
            class="btn btn-primary font-weight-bold text-uppercase px-15 py-4 my-3 mr-3"
            >Abonement abschliessen
          </b-button>
        </router-link>
      </div>
    </div>
    <!--end::Body-->
  </div>
  <!--end::Subscription Widget-->
</template>

<script>
import { utilMixin } from "@/mixins/utilMixin";
// import OrdersPropertyTableRow from "@/view/content/widgets/components/OrdersPropertyTableRow";

export default {
  components: {},
  mixins: [utilMixin],
  name: "SubscriptionWidget",
  props: {
    subscriptionOrder: {
      required: true
    },
    company: {
      required: true
    }
  },
  computed: {}
};
</script>
