<template>
  <!--begin::Invitation code Widget-->
  <div class="card card-custom gutter-b">
    <!--begin::Header-->
    <div class="card-header border-0 py-5">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label font-weight-bolder text-dark"
          >Einladungscode</span
        >
        <!--        <span class="text-muted mt-3 font-weight-bold font-size-sm"-->
        <!--          >Dies ist ihr persönlicher Einladungscode.</span-->
        <!--        >-->
      </h3>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body pt-0 pb-3">
      <span class="text-dark-75 d-block font-size-lg">
        Dies ist ihr persönlicher Einladungscode. Sobald sich jemand mit diesem
        Einladungscode registriert und ein kostenpflichtiges Abonement
        abschliesst, erhalten Sie von uns CHF
        {{ formatFloat(subscriptionPricing.invitation_commission_amount) }}.
        Senden Sie diesen Code ausgedruck oder per Mail an potentielle
        Submito.ch Benutzer um von diesem Angebot zu profitieren.
      </span>
      <h1 class="pt-3">
        <b-badge variant="dark">{{ userProfile.invitation_code }}</b-badge>
      </h1>
    </div>
    <!--end::Body-->
  </div>
  <!--end::Invitation code Widget-->
</template>

<script>
import { utilMixin } from "@/mixins/utilMixin";

export default {
  name: "InvitationCodeWidget",
  mixins: [utilMixin],
  props: {
    userProfile: {
      required: true
    },
    subscriptionPricing: {
      required: true
    }
  }
};
</script>

<style scoped></style>
